<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form class="ruleForm" :model="ruleForm" :rules="rules" ref="ruleForm">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{ pageTitleName }}
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="原因名称" prop="name">
                <el-input v-model="ruleForm.name" placeholder="请输入管理原因"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" class="position-relative">
              <el-form-item label="班级">
                <el-select v-model="ruleForm.classId" placeholder="请选择" class="formControl" @change="onChangeClass()">
                  <el-option v-for="item in optClass" :key="item.classId" :label="item.name" :value="item.classId"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="所属" prop="parentIds">
                <el-cascader
                  v-model="parentIds"
                  :options="treeType"
                  @change="handleChange"
                  style="width: 100% !important"
                  :props="{ label: 'name', value: 'reasonId', children: 'children', checkStrictly: true }"
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="6" class="position-relative">
              <el-form-item label="分数录入方式">
                <el-select v-model="ruleForm.inputScoreMode" placeholder="请选择" class="formControl" :disabled="ruleForm.reasonId == null ? false : true">
                  <el-option v-for="item in optFraction" :key="item.Value" :label="item.Text" :value="item.Value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="优先级" prop="priority">
                <el-input @change="onPriorityChange()" v-model="ruleForm.priority" placeholder="请输入优先级"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注" prop="description">
                <el-input
                  class="formControl"
                  type="textarea"
                  :autosize="{ minRows: 5, maxRows: 10 }"
                  show-word-limit
                  placeholder="请输入备注"
                  maxlength="500"
                  v-model="ruleForm.description"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </el-form>

    <!--选择模式录入-->
    <el-card class="box-card" v-if="ruleForm.inputScoreMode == 1">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          选择模式录入
        </h3>
        <div class="card-tools">
          <el-button type="text" class="btn btn-success btn-xs" @click="dialogVisible = true">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#fax-circle-plus-alt"></use>
            </svg>
            新增
          </el-button>
        </div>
      </div>
      <div class="card-body">
        <div class="callout callout-warning">
          <div class="tipCont">1、该规则的分数是在设定的分数中选择，不能自定义录入</div>
          <div class="tipCont">2、在用户选择时按从小到大排序；默认值有且只有一个，如果未设置默认值，在保存时系统会把最小分数设置为默认值；</div>
          <div class="tipCont">3、红色星号为默认值</div>
        </div>
        <el-table :data="arrFractionForSel" stripe class="elTableData">
          <el-table-column label="序号" width="120">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
              <svg class="icon color-danger" aria-hidden="true" v-if="scope.row.isDefault">
                <use xlink:href="#fax-star-alt"></use>
              </svg>
            </template>
          </el-table-column>
          <el-table-column prop="score" label="分数" class="elColumn"></el-table-column>
          <el-table-column label="操作" class="elColumn" width="100">
            <template slot-scope="scope">
              <el-button size="mini" type="danger" @click="onRemoveSel(scope.row.deployId)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>

    <!--次数累加计算录入-->
    <el-card class="box-card" v-if="ruleForm.inputScoreMode == 2">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          次数累加计算录入
        </h3>
        <div class="card-tools">
          <el-button type="text" class="btn btn-success btn-xs" @click="dialogVisible = true">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#fax-circle-plus-alt"></use>
            </svg>
            新增
          </el-button>
        </div>
      </div>
      <div class="card-body">
        <div class="callout callout-warning">
          <div class="tipCont">
            1、按次数累计计算录入：表示录入同一个规则后，系统会自动计算在一个周期内录入的次数，然后会匹配对应的分数；录入次数超过设置的次数以最大次数对应的分数为准
          </div>
          <div class="tipCont">2、在用户选择时按从小到大排序；默认值有且只有一个，如果未设置默认值，在保存时系统会把最小分数设置为默认值；</div>
          <div class="tipCont">3、红色星号为默认值</div>
        </div>
        <el-row :gutter="24">
          <el-col :span="6">
            <el-form class="ruleForm">
              <el-form-item label="自动计算的周期">
                <el-select v-model="ruleForm.cycle" placeholder="请选择自动计算的周期" class="formControl">
                  <el-option v-for="item in optCycle" :key="item.Value" :label="item.Text" :value="item.Value"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="24">
            <el-table :data="arrFractionForGrand" stripe class="elTableData">
              <el-table-column label="序号" width="120">
                <template slot-scope="scope">
                  {{ scope.$index + 1 }}
                  <svg class="icon color-danger" aria-hidden="true" v-if="scope.row.isDefault">
                    <use xlink:href="#fax-star-alt"></use>
                  </svg>
                </template>
              </el-table-column>
              <el-table-column prop="number" label="数量" class="elColumn"></el-table-column>
              <el-table-column prop="score" label="分数" class="elColumn"></el-table-column>
              <el-table-column prop="unit" label="单位" class="elColumn"></el-table-column>
              <el-table-column label="操作" class="elColumn" width="100">
                <template slot-scope="scope">
                  <el-button size="mini" type="danger" @click="onRemoveSel(scope.row.tempID)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <!--数量计算录入-->
    <el-card class="box-card" v-if="ruleForm.inputScoreMode == 3">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          数量计算录入
        </h3>
      </div>
      <div class="card-body">
        <div class="callout callout-warning">
          <div class="tipCont">1、在扣（加）分的时候会要求输入数量，所扣（加）分数是：分数 * 数量</div>
        </div>
        <el-form class="ruleForm" ref="ruleNum" :model="itemFractionForNum" :rules="rulesDialog">
          <el-row :gutter="24">
            <el-col :span="6">
              <el-form-item label="分数" prop="score">
                <el-input v-model="itemFractionForNum.score" placeholder="请输入" class="formControl"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="计量单位" prop="unit">
                <el-input v-model="itemFractionForNum.unit" placeholder="请输入" class="formControl"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>

    <!--数量计算录入-->
    <el-card class="box-card" v-if="ruleForm.inputScoreMode == 4">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          自义定录入
        </h3>
      </div>
      <div class="card-body">分数是由执行者手动录入，不做任何限制</div>
    </el-card>
    <!-- 弹出框 -->
    <el-dialog title="新增分数" :visible.sync="dialogVisible" width="30%" :before-close="handleClose" :close-on-click-modal="false">
      <div class="card-body">
        <el-form class="ruleForm" ref="ruleDialog" :model="ruleDialog" :rules="rulesDialog">
          <el-form-item label="分数" prop="score">
            <el-input v-model="ruleDialog.score" placeholder="请输入分数" class="formControl"></el-input>
          </el-form-item>
          <el-form-item label="数量" prop="number" v-if="ruleForm.inputScoreMode == 2">
            <el-input v-model="ruleDialog.number" placeholder="请输入" class="formControl"></el-input>
          </el-form-item>
          <el-form-item label="单位" v-if="ruleForm.inputScoreMode == 2">
            <el-input v-model="ruleDialog.unit" placeholder="请输入" class="formControl"></el-input>
          </el-form-item>
          <el-form-item label="是否设置为默认" v-if="ruleForm.inputScoreMode == 1">
            <el-switch v-model="ruleDialog.isDefault" class="formControl"></el-switch>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="onCreateFraction">确 定</el-button>
      </span>
    </el-dialog>

    <div class="row mt-3">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack">返回</el-button>
        <el-button icon="el-icon-refresh" @click="onReset()">重置</el-button>
        <el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      // 加载动画
      fullscreenLoading: false,
      defaultConfig: [],
      //综合类型
      configs: [],
      //班级评价类型
      configsClass: [],
      // 综合评价
      growthConfigs: [],
      growthConfigsReset: null,
      // 必填项
      ruleForm: {
        reasonId: null, //ID
        name: null, // 名称
        score: 0,
        priority: null, //优先级
        parentId: null, //父节点id
        description: null, //描述
        configs: null,
        cycle: null, // 周期
        classId: null,
        // 教师角色
        reasonRoles: [],
        // 学生角色
        reasonAssociations: [],
        reasonType: 2, // 2：管理原因
        inputScoreMode: '', // 分数类型
        reasonDeploys: [], // 分数集合
        parentId: null
      },
      treeType: [],
      // 验证
      rules: {
        name: [
          {
            required: true,
            message: '原因不能为空',
            trigger: 'change'
          },
          {
            max: 64,
            message: '原因不能超过64个字符',
            trigger: 'change'
          }
        ],
        parentId: [
          {
            required: true,
            message: '请选择类型',
            trigger: 'change'
          }
        ],
        score: [
          {
            required: true,
            message: '请输入分值',
            trigger: 'change'
          }
        ],
        priority: [
          {
            validator: (rule, value, callback) => {
              let that = this;
              var reg = /^-?[1-9]\d*$/;
              if (value != 0 && !reg.exec(value)) {
                callback(new Error('优先级只能输入整数！'));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ]
      },
      ruleFormReset: null,
      // 所属类型
      moralityTree: [],
      random: null,
      // 管理原因对象 1：班级 2：班级个人 3：宿舍 4：宿舍个人
      currentType: 1,
      // 是根据对象显示页面名称
      pageTitleName: '新增班级德育分原因',
      // 教师角色
      roles: [],
      // 学生角色
      studentRoles: [],
      isFractionType: '',
      // 分数录入方式
      optFraction: [
        {
          Value: '1',
          Text: '按选择模式录入'
        },
        {
          Value: '4',
          Text: '自定义录入'
        }
      ],
      // 录入方式:选择模式
      arrFractionForSel: [],
      arrFractionForSelReset: null,
      // 录入方式:按次数累加计算
      arrFractionForGrand: [],
      arrFractionForGrandReset: null,
      // 按数量计算
      itemFractionForNum: {
        score: '', //分数
        unit: '' // 单位
      },
      itemFractionForNumReset: null,

      // 弹出框
      dialogVisible: false,
      // 弹出框 自定义录入分数
      ruleDialog: {
        score: '', //分数
        number: '', // 数量
        unit: '', // 单位
        isDefault: false
      },
      ruleDialogReset: null,
      // 弹出框验证
      rulesDialog: {
        number: [
          {
            required: true,
            message: '请输入数量',
            trigger: 'change'
          },
          {
            validator: (rule, value, callback) => {
              let that = this;
              var reg = /^[0-9]*$/;
              if (value != 0 && !reg.exec(value)) {
                callback(new Error('数量只能输入正整数！'));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ],
        score: [
          {
            required: true,
            message: '请输入分值',
            trigger: 'change'
          },
          {
            validator: (rule, value, callback) => {
              let that = this;
              var reg = /^(\-|\+)?\d+(\.\d{1,2})?$/;
              if (value != 0 && !reg.exec(value)) {
                callback(new Error('分数只能输入带1-2位小数的正数或负数！'));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ],
        unit: [
          {
            required: true,
            message: '请输入单位',
            trigger: 'change'
          }
        ]
      },
      // 周期
      optCycle: [
        /* {
          value: '1',
          label: '天'
        }, {
          value: '2',
          label: '周'
        }, {
          value: '3',
          label: '月'
        }, {
          value: '4',
          label: '学期'
        }, */
      ],
      parentIds: [],
      TeacherInfo: {},
      optClass: []
    };
  },
  methods: {
    handleChange(val) {
      let that = this;
      that.ruleForm.parentId = val[val.length - 1];
    },
    onChangeClass() {
      let that = this;
      let data = {
        classId: that.ruleForm.classId
      };
      ajaxGet(that, '/api/admin/classcreditreason/treelist', data, function (res) {
        that.treeType = res;
        that.treeType.forEach((item) => {
          removeChildren(item);
        });
      });
    },
    handleTypesChange(Id, val) {
      this.growthConfigs.forEach((ele) => {
        if (ele.configId == Id) {
          ele.isSelect = val.isSelect;
          ele.score = val.score;
        }
      });
    },
    /**
     * @description 删除【选择模式录入】的分数
     * @param {String} _valId 数据ID
     * @return {void}
     */
    onRemoveSel(_valId) {
      let that = this;
      if (that.ruleForm.inputScoreMode == 1) {
        let tempArr = that.arrFractionForSel.filter((val) => val.deployId != _valId);
        that.arrFractionForSel = tempArr;
      } else if (that.ruleForm.inputScoreMode == 2) {
        let tempArr = that.arrFractionForGrand.filter((val) => val.deployId != _valId);
        that.arrFractionForGrand = tempArr;
      }
    },

    /**
     * @description 弹出框提交按钮
     * @return {void}
     */
    onCreateFraction() {
      let that = this;
      that.$refs['ruleDialog'].validate((valid) => {
        if (valid) {
          // 按选择模式录入
          if (that.ruleForm.inputScoreMode == 1) {
            // 判断是否存在同分值
            if (that.arrFractionForSel.find((val) => setTwoDecimal(val.score) == setTwoDecimal(that.ruleDialog.score)) == undefined) {
              if (that.ruleDialog.isDefault) {
                for (var item of that.arrFractionForSel) {
                  item.isDefault = false;
                }
              }
              that.arrFractionForSel.push({
                deployId: timeTransform(new Date(), 'YYYYMMDD-HHmmss-SSS'), //临时ID
                score: that.ruleDialog.score,
                isDefault: that.ruleDialog.isDefault,
                number: 0,
                unit: null
              });
            } else {
              warning(that, '已存在同分值的分数！');
            }
          } else if (that.ruleForm.inputScoreMode == 2) {
            // 判断是否存在同分值
            if (that.arrFractionForGrand.find((val) => setTwoDecimal(val.score) == setTwoDecimal(that.ruleDialog.score)) == undefined) {
              if (that.ruleDialog.isDefault) {
                for (var item of that.arrFractionForGrand) {
                  item.isDefault = false;
                }
              }
              that.arrFractionForGrand.push({
                deployId: timeTransform(new Date(), 'YYYYMMDD-HHmmss-SSS'), //临时ID
                number: parseInt(that.ruleDialog.number),
                score: that.ruleDialog.score,
                unit: that.ruleDialog.unit,
                isDefault: that.ruleDialog.isDefault
              });
            } else {
              warning(that, '已存在同分值的分数！');
            }
          }

          // 关闭弹出框
          that.handleClose();
        }
      });
    },

    /**
     * @description 返回按钮
     * @return {void}
     */
    onBack() {
      let that = this;
      back(that);
    },

    /**
     * @description 优先级
     * @return {void}
     */
    onPriorityChange() {
      let that = this;
      let temp = setInt(that.ruleForm.priority);
      that.ruleForm.priority = temp;
    },

    /**
     * @description 关闭弹出框
     * @return {void}
     */
    handleClose() {
      let that = this;
      that.dialogVisible = false;
      that.$refs['ruleDialog'].resetFields();
      that.ruleDialog = JSON.parse(JSON.stringify(that.ruleDialogReset));
    },

    /**
     * @description 所属类型选择事件
     * @param {Array} 类型ID
     * @return {void}
     */
    getValue(_val) {
      let that = this;
      that.ruleForm.parentId = _val;
    },

    /**
     * @description 重置按钮
     * @return {void}
     */
    onReset() {
      let that = this;
      that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
      that.growthConfigs = JSON.parse(JSON.stringify(that.growthConfigsReset));
      that.ruleDialog = JSON.parse(JSON.stringify(that.ruleDialogReset));
      if (regNull(that.ruleForm.reasonId)) {
        that.itemFractionForNum = JSON.parse(JSON.stringify(that.itemFractionForNumReset));
        that.arrFractionForSel = [];
        that.arrFractionForGrand = [];
        that.$refs.refType.clearHandle();
      } else {
        that.arrFractionForGrand = JSON.parse(JSON.stringify(that.arrFractionForGrandReset));
        that.arrFractionForSel = JSON.parse(JSON.stringify(that.arrFractionForSelReset));
      }
    },

    /**
     * @description 提交按钮
     * @return {void}
     */
    onSave() {
      let that = this;
      // 设置优先级，默认值为0
      that.ruleForm.priority = Number(that.ruleForm.priority);
      // 管理原因为2
      that.ruleForm.reasonType = 2;

      // 分数录入方式
      if (!regNull(that.ruleForm.inputScoreMode)) {
        that.ruleForm.inputScoreMode = parseInt(that.ruleForm.inputScoreMode);
        if (that.ruleForm.inputScoreMode == 1) {
          // 验证选择模式录入
          if (that.arrFractionForSel == null || that.arrFractionForSel.length <= 0) {
            warning(that, '请添加选择模式录入方式的分数');
            return false;
          } else {
            that.ruleForm.points = that.arrFractionForSel;
          }
        }
      } else {
        that.ruleForm.inputScoreMode = 4;
      }

      // 表单验证
      that.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          // 判断是新增 或 编辑
          if (regNull(that.ruleForm.reasonId)) {
            // 新增
            that.fullscreenLoading = true;

            ajaxPost(that, '/api/admin/classcreditreason', that.ruleForm, function (res) {
              that.fullscreenLoading = false;
              back(that);
            });
          } else {
            // 编辑
            if (that.ruleForm.reasonId == that.ruleForm.parentId && that.ruleForm.reasonId != null) {
              warning(that, '不能把所属设置在自身级别下');
              return false;
            }
            that.fullscreenLoading = true;
            ajaxPut(that, '/api/admin/classcreditreason' + '/' + that.ruleForm.reasonId, that.ruleForm, function (res) {
              that.fullscreenLoading = false;
              back(that);
            });
          }
        }
      });
    }
  },
  created() {
    let that = this;
    let _TypeID = that.$route.params.Type;
    let _DataID = that.$route.params.Id;
    that.ruleDialogReset = JSON.parse(JSON.stringify(that.ruleDialog));
    that.itemFractionForNumReset = JSON.parse(JSON.stringify(that.itemFractionForNum));
    that.TeacherInfo = onGetNowUser(that);
    let resultClass = that.TeacherInfo.isTeacher ? that.TeacherInfo.manageClass : that.TeacherInfo.allClass;
    if (!that.TeacherInfo.isTeacher) {
      that.optClass = [
        {
          classId: null,
          name: '全校通用'
        }
      ];
      resultClass.forEach((item) => {
        that.optClass.push({
          classId: item.classId,
          name: item.alias
        });
      });
    } else {
      that.optClass = resultClass.map((item) => ({
        classId: item.classId,
        name: item.alias
      }));
      that.ruleForm.classId = resultClass[0].classId;
    }
    that.onChangeClass();
    if (!regNull(_DataID)) {
      ajaxGet(that, '/api/admin/classcreditreason/' + _DataID, null, function (res) {
        that.pageTitleName = '编辑班级德育分原因';
        that.ruleForm = res;
        if (res.inputScoreMode == 1) {
          that.arrFractionForSel = res.points;
          that.arrFractionForSelReset = JSON.parse(JSON.stringify(that.arrFractionForSel));
        }
        that.ruleForm.inputScoreMode = that.ruleForm.inputScoreMode.toString();
        let data = {
          classId: that.ruleForm.classId
        };
        ajaxGet(that, '/api/admin/classcreditreason/treelist', data, function (res) {
          that.treeType = res;
          that.treeType.forEach((item) => {
            removeChildren(item);
          });
          if (!regNull(that.ruleForm.parentId)) {
            that.parentIds = findPath(that.treeType, that.ruleForm.parentId);
            // console.log(that.parentIds,)
          }
        });
        that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
      });
    } else {
      that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
    }
  }
};
function removeChildren(tree) {
  if (tree.children && tree.children.length > 0) {
    tree.children.forEach((child) => {
      removeChildren(child);
    });
  } else {
    tree.children = undefined; // 或者 tree.children = undefined;
  }
}
function findPath(tree, id) {
  for (let i = 0; i < tree.length; i++) {
    if (tree[i].reasonId === id) {
      return [tree[i].reasonId];
    }
    if (tree[i].children) {
      let path = findPath(tree[i].children, id);
      if (path) {
        return [tree[i].reasonId].concat(path);
      }
    }
  }
}
</script>

<style scoped="scoped">
.spTipCont {
  font-size: 12px;
  color: #767676;
  position: absolute;
  top: 13px;
  left: 120px;
}

.tipCont {
  font-size: 14px;
}
</style>
<style>
.tab-content .el-checkbox {
  margin-bottom: 0;
}
</style>
